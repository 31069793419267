body {
    background: #C9D6FF;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #E2E2E2, #C9D6FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.catbox-section {
    width: 100%;
    height: 100vh;
    position: relative
}

.catbox-container {
    width: 100%;
    height: 100%;
    max-width: 1280px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.catbox-content {
    display: flex;
    flex-direction: column;
    width: 85%;
    justify-content: center;
}

.catbox-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    position: relative;
}

.catbox-header-span, catbox-header-span-talk {
    color: #432b51;
    font-size: 30px;
    position: absolute;
    bottom: 20px;
    font-family: 'Quicksand-medium';
    font-weight: 600;
}

.catbox-header-span-talk, .catbox-header-span-start {
    bottom: -20px;
    color: #432b51;
    font-size: 15px;
    position: absolute;
    font-family: 'Quicksand-medium';
    font-weight: normal;
}

.catbox-header-span-start {
    bottom: -40px;
}

.cat-container {
    width: 100%;
    cursor: pointer;
}

@media screen and (min-width: 1030px) {
    .catbox-content {
        max-width: 1280px;
        margin: auto
    }
    .catbox-header {
        width: 50%;
        margin: auto
    }
    .catbox-header-span {
        margin-bottom: 80px
    }
    .catbox-header-span-talk {
        margin-bottom: 70px
    }
    .catbox-header-span-start {
        margin-bottom: 60px
    }
    .chatbox-chat-container {
        width: 50% !important;
        margin: auto
    }
    .chatbox-chat-body::-webkit-scrollbar {
        width: 0px;
        display: none
    }
}